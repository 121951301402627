import Alpine from 'alpinejs';

window.Alpine = Alpine;

Alpine.start();

const env = document.querySelector('body')?.dataset.env || 'production';

if ('serviceWorker' in navigator && env === 'production') {
  window.addEventListener('load', async () => {
    try {
      await navigator.serviceWorker.register('/sw.js');
      console.log('Service Worker registered');
    } catch (error) {
      console.error('Service worker registration failed:', error);
    }
  });
}

// cards redundant click, accessible whole card clickable solution by Heydon Pickering

const cards = [...document.querySelectorAll('.card')];
cards.forEach((card) => {
  card.style.cursor = 'pointer';
  let down,
    up,
    link = card.querySelector('h3 a');
  card.onmousedown = () => (down = +new Date());
  card.onmouseup = () => {
    up = +new Date();
    if (up - down < 200) {
      link.click();
    }
  };
});

// find overflow

document.querySelectorAll('*').forEach((el) => {
  if (el.offsetWidth > document.documentElement.offsetWidth) {
    console.log('Found the worst element ever: ', el);
  }
});
